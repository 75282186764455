import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderMarkets from "../components/headers/header-markets";
import PromiseAppEngIt from "../components/markets/promise-app-eng-it";
import Help from "../components/global/help";
import Projects from "../components/global/projects";
import IconLayers from "../assets/images/markets/layers.svg";
import IconCube from "../assets/images/markets/cube.svg";
import IconFingerprint from "../assets/images/markets/fingerprint.svg";
import IconRobot from "../assets/images/markets/robot.svg";
import HeaderBcg from "../assets/images/headers/real-estate.png"



const RealEstate = () => {
  const { t } = useTranslation()
  // Header Content
  const hTitle = `${t('markets.realEstate')}`;
  const hShortText = `${t('marketsPages.realEstate.shortText')}`;
  const hText = `${t('marketsPages.realEstate.text')}`;
  const helpHeadline = `${t('marketsPages.helpHeadline')}`;

  // Promise Component
  const helpContent = [
    {
      title: `${t('marketsPages.realEstate.helpContent.firstCard.title')}`,
      textOne: `${t('marketsPages.realEstate.helpContent.firstCard.textOne')}`,
      textTwo: `${t('marketsPages.realEstate.helpContent.firstCard.textTwo')}`,
      img: <IconLayers />,
    },
    {
      title: `${t('marketsPages.realEstate.helpContent.secondCard.title')}`,
      textOne: `${t('marketsPages.realEstate.helpContent.secondCard.textOne')}`,
      textTwo: `${t('marketsPages.realEstate.helpContent.secondCard.textTwo')}`,
      img: <IconCube />,
    },
    {
      title: `${t('marketsPages.realEstate.helpContent.thirdCard.title')}`,
      textOne: `${t('marketsPages.realEstate.helpContent.thirdCard.textOne')}`,
      textTwo: `${t('marketsPages.realEstate.helpContent.thirdCard.textTwo')}`,
      img: <IconFingerprint />,
    },
    {
      title: `${t('marketsPages.realEstate.helpContent.fourthCard.title')}`,
      textOne: `${t('marketsPages.realEstate.helpContent.fourthCard.textOne')}`,
      textTwo: `${t('marketsPages.realEstate.helpContent.fourthCard.textTwo')}`,
      img: <IconRobot />,
    },
  ];
  return (
    <Layout>
      <Seo title={hTitle} />
      <HeaderMarkets
        hTitle={hTitle}
        hShortText={hShortText}
        hText={hText}
        bkgImage={HeaderBcg}
        hNum={'52'}
      />
      <Help helpContent={helpContent} helpHeadline={helpHeadline} />
      <PromiseAppEngIt promiseContentItems={['app-dev', 'eng-team', "other-services"]} />
      <Projects />
    </Layout>
  );
};

export default RealEstate;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`